import dayjs from "dayjs";
import { IFilterSort } from "../design-system/table/TableInterface";

const backToList = (history: any, pathname: string, state: any) => {
  history.push({
    pathname: pathname,
    state: { ...(state || {}), view: "view", viewData: null },
  });
};

const resetTimeToMidnight = (isoTime: string, type: "start" | "end") => {
  const localDate = new Date(isoTime)?.toLocaleDateString();
  // Split the input string to extract month, day, and year
  const [month, day, year] = localDate.split("/").map(Number);

  // Create a Date object with the parsed date (month is 0-indexed, so subtract 1)
  const date =
    type === "start"
      ? dayjs(`${year}-${month}-${day}`).startOf("day").toISOString()
      : dayjs(`${year}-${month}-${day}`).endOf("day").toISOString();

  // Convert the Date object to ISO string
  return date;
};

const generateDate = (data: string | undefined) => {
  if (!data) return "--";
  const formatDate = (date: any) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  };
  return formatDate(new Date(data)?.toLocaleDateString());
};

const generateSort = (
  sortName: string,
  sortData: IFilterSort,
  setSortData: (sort: IFilterSort) => void
) => {
  return {
    default: "DESC",
    isActive: sortData?.sortColumn === sortName,
    activeColumn: sortData?.sortColumn,
    onSort: (currentDirection: string) => {
      setSortData({
        sortColumn: sortName,
        sortDirection: currentDirection,
      });
    },
  };
};

const pxToRem = (sizeInPx: number) => {
  return sizeInPx / (window.innerWidth >= 1200 ? 16 : 12);
};

export { backToList, resetTimeToMidnight, generateDate, generateSort, pxToRem };
