import React, { KeyboardEvent, useState } from "react";

import ctl from "@netlify/classnames-template-literals";
import LoadingSpinner from "./LoadingSpinner";

interface ButtonProps {
  id: string;
  variant?: string;
  children?: (string | JSX.Element)[] | JSX.Element | string | React.ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onTab?: () => void;
  onShiftTab?: () => void;
  wrapperCustomClass?: string;
  customClass?: string;
  stopPropagation?: boolean;
  key?: string;
  typographyClass?: string;
  noPadding?: boolean;
  useSpinner?: boolean;
}

const Button = ({
  id,
  variant = "primary",
  children,
  disabled = false,
  isLoading = false,
  onClick,
  onTab,
  onShiftTab,
  wrapperCustomClass,
  customClass,
  stopPropagation = true,
  key,
  typographyClass = "typography-subtitle-bold",
  noPadding = false,
  useSpinner = false,
}: ButtonProps) => {
  const isDisabled = disabled || isLoading;

  const getVariantClassName = () => {
    return ctl(
      `
        w-fit
        ${disabled ? "cursor-not-allowed" : "cursor-pointer"}

        ${
          variant === "primary" &&
          `
            border-0
            bg-cp-600
            text-n-000
            ${!noPadding && "px-[2.5rem] py-[0.5rem]"}
          `
        }

        ${
          variant === "secondary" &&
          `
            border
            border-solid
            bg-n-000
            ${!noPadding && "px-[2.5rem] py-[0.4375rem]"}
            ${
              isDisabled
                ? "border-cp-200 text-cp-400"
                : "border-cp-600 text-cp-600"
            }
          `
        }

        ${
          variant === "tertiary" &&
          `
            border-0
            bg-transparent
            text-cp-600
            ${!noPadding && "px-[0.5rem] py-[0.5rem]"}
          `
        }

        ${isDisabled && variant === "tertiary" && "text-n-400"}

        ${
          isDisabled &&
          variant === "primary" &&
          `disabled cursor-not-allowed border-0 bg-n-200 text-n-000 ${
            !noPadding && "px-[2.5rem] py-[0.5rem]"
          }`
        }
      `
    );
  };

  const focusIndicatorCN = ctl(`
    h-fit w-fit p-[0.25rem] rounded-full
    focus-within:[&:has(:focus-visible)]:border focus-within:[&:has(:focus-visible)]:border-solid
    focus-within:[&:has(:focus-visible)]:border-cp-600 focus-within:[&:has(:focus-visible)]:outline-0
    focus-within:[&:has(:focus-visible)]:p-[0.1875rem]
    ${wrapperCustomClass}
  `);

  const btnCN = ctl(`
    flex
    justify-center
    min-h-[2.25rem]
    items-center
    whitespace-nowrap
    rounded-[2.5rem]
    ${typographyClass}
    ${getVariantClassName()}
    ${customClass}
  `);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    stopPropagation && e.stopPropagation();
    onClick && onClick(e);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (e?.key === "Tab") {
      e.preventDefault();
      if (e?.shiftKey) {
        onShiftTab && onShiftTab();
      } else {
        onTab && onTab();
      }
    }
  };

  return (
    <div className={focusIndicatorCN}>
      <button
        id={`${id}-button`}
        key={key || id}
        className={btnCN}
        style={{ outline: "none" }}
        onClick={(e) => handleClick(e)}
        onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => onKeyDown(e)}
        disabled={isDisabled}
        tabIndex={-1}
        data-cy={id}
        data-testid={`${id}-button`}
      >
        {isLoading && useSpinner && <LoadingSpinner />}
        {children}
      </button>
    </div>
  );
};

type ButtonVariantProps = Omit<ButtonProps, "variant">;

const Secondary = (props: ButtonVariantProps) => {
  return <Button variant="secondary" {...props} />;
};

const Tertiary = (props: ButtonVariantProps) => {
  return <Button variant="tertiary" {...props} />;
};

interface ISubmitButton extends ButtonProps {
  onClick: () => Promise<any>;
}
const Submit = (props: ISubmitButton) => {
  const [localDisabled, setLocalDisabled] = useState(false);
  const disabled = localDisabled || props.disabled || false;

  const handleClick = async () => {
    setLocalDisabled(true);
    props.onClick && (await props.onClick());
    setLocalDisabled(false);
  };

  return (
    <Button
      {...props}
      variant="primary"
      onClick={handleClick}
      disabled={disabled}
    />
  );
};

Button.Secondary = Secondary;
Button.Tertiary = Tertiary;
Button.Submit = Submit;

export default Button;
